import React from 'react';
import PomodoroApp from './components/PomodoroApp';

function App() {
  return (
    <div className="App">
      <PomodoroApp />
    </div>
  );
}

export default App;