import React, { useState, useEffect } from 'react';
import { Play, Pause, RotateCcw } from 'lucide-react';
import { Card, CardContent, CardHeader } from './ui/card';
import { Button } from './ui/button';

const PomodoroApp = () => {
  const [time, setTime] = useState(25 * 60);
  const [isActive, setIsActive] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [logs, setLogs] = useState([]);
  const [currentLog, setCurrentLog] = useState({ name: '', description: '' });

  useEffect(() => {
    const timer = setInterval(() => setCurrentDateTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    let interval = null;
    if (isActive && time > 0) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (time === 0) {
      setIsActive(false);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  const toggleTimer = () => {
    setIsActive(!isActive);
  };

  const resetTimer = () => {
    setTime(25 * 60);
    setIsActive(false);
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const logTime = () => {
    if (currentLog.name.trim() !== '' && currentLog.description.trim() !== '') {
      setLogs([...logs, { ...currentLog, time: new Date().toLocaleTimeString() }]);
      setCurrentLog({ name: '', description: '' });
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Pomodoro</h1>
        <div className="text-sm font-medium">
          <div className="text-3xl font-bold">{currentDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
          <div className="text-right">{currentDateTime.toLocaleDateString([], { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="space-y-4">
          <Card>
            <CardHeader className="flex justify-between items-center">
              <h2 className="text-xl font-semibold">Pomodoro</h2>
            </CardHeader>
            <CardContent>
              <div className="text-6xl font-bold text-center my-8">{formatTime(time)}</div>
              <div className="flex justify-center space-x-4 mb-4">
                <Button onClick={toggleTimer} variant="outline" size="lg">
                  {isActive ? <Pause className="h-6 w-6" /> : <Play className="h-6 w-6" />}
                </Button>
                <Button onClick={resetTimer} variant="outline" size="lg">
                  <RotateCcw className="h-6 w-6" />
                </Button>
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardContent className="p-4">
              <h3 className="text-lg font-semibold mb-2">YouTube Stream</h3>
              <div className="aspect-w-16 aspect-h-9">
                <iframe 
                  src="https://www.youtube.com/embed/jfKfPfyJRdk?si=rViqqZiSXgSKyz0A" 
                  title="YouTube video player" 
                  frameBorder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  referrerPolicy="strict-origin-when-cross-origin" 
                  allowFullScreen
                  className="w-full h-full"
                ></iframe>
              </div>
            </CardContent>
          </Card>
        </div>
        <Card>
          <CardHeader className="space-y-2">
            <input
              type="text"
              value={currentLog.name}
              onChange={(e) => setCurrentLog({...currentLog, name: e.target.value})}
              placeholder="Session Name"
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="text"
              value={currentLog.description}
              onChange={(e) => setCurrentLog({...currentLog, description: e.target.value})}
              placeholder="Session Description"
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <Button 
              onClick={logTime} 
              className="w-full mt-4 bg-black hover:bg-gray-800 text-white font-semibold py-3 px-6 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
            >
              Log Time
            </Button>   
          </CardHeader>
          <CardContent>
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left">Time</th>
                  <th className="text-left">Name</th>
                  <th className="text-left">Description</th>
                </tr>
              </thead>
              <tbody>
                {logs.map((log, index) => (
                  <tr key={index}>
                    <td className="py-2">{log.time}</td>
                    <td className="py-2">{log.name}</td>
                    <td className="py-2">{log.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardContent>
        </Card>
      </div>
      <Card className="mt-4">
        <CardContent className="text-center py-4">
          Boost your productivity with our free, easy-to-use Pomodoro Timer app. Stay focused, manage your time effectively, and achieve more in less time. Features include customizable work/break intervals, task logging, and integrated YouTube streaming for background music or study ambiance. Perfect for students, professionals, and anyone looking to enhance their time management skills. Try our Pomodoro technique tool today and transform the way you work and study!
        </CardContent>
      </Card>
    </div>
  );
};

export default PomodoroApp;